import { type VariantProps, cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-neutral-900 text-neutral-50 hover:bg-neutral-900/90',
        destructive:
          'bg-[#AF1D38] text-neutral-50 hover:bg-[#AF1D38]/90',
        outline:
          'border border-gray-200 bg-white hover:bg-stroke hover:border-stroke hover:text-neutral-900',
        secondary:
          'bg-white border text-neutral-900 hover:bg-neutral-100/80',
        ghost: 'hover:bg-stroke hover:text-neutral-900',
        link: 'text-neutral-900 hover:text-primary-600',
        primary: 'bg-primary-500 text-white hover:bg-primary-600',
        lighter: 'bg-stroke text-white hover:bg-transparent border border-transparent hover:border-gray-200 text-gray-500 hover:text-main',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        xs: 'h-7 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
