import {RoleEnum} from "~/types/User";

export interface TabItem {
    role: RoleEnum,
    path: string;
    icon: string;
    name: string;
    child_pages: string[];
    show_in_mobile: boolean,
    show_in_desktop: boolean,
}

export function useNavTabs() {
    return ref<TabItem[]>([
        {
            role: RoleEnum.CUSTOMER,
            name: 'My tasks',
            icon: 'ri:file-list-line',
            path: '/customer-office',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.CUSTOMER,
            name: 'Task archive',
            icon: 'ri:archive-line',
            path: '/customer-office/orders-archive',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.CUSTOMER,
            name: 'Home',
            icon: 'ri:home-6-line',
            path: '/',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: false,
        },
        {
            role: RoleEnum.CUSTOMER,
            name: 'Chats',
            icon: 'ri:chat-3-line',
            path: '/customer-office/chats',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.CUSTOMER,
            name: 'My profile',
            icon: 'ri:user-3-line',
            path: '/customer-office/profile',
            child_pages: ['/customer-office/profile/notification-settings', '/customer-office/profile/privacy-security'
            ],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'My tasks',
            icon: 'ri:dashboard-line',
            path: '/master-office',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'Chats',
            icon: 'ri:chat-3-line',
            path: '/master-office/chats',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'Task Search',
            icon: 'ri:search-2-line',
            path: '/master-office/task-search',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'Task archive',
            icon: 'ri:archive-line',
            path: '/master-office/task-archive',
            child_pages: [],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'My profile',
            icon: 'ri:user-3-line',
            path: '/master-office/profile',
            child_pages: ['/master-office/profile/notification-settings',
                '/master-office/profile/privacy-security'/* , '/master-office/profile/billing' */],
            show_in_mobile: true,
            show_in_desktop: true,
        },
        {
            role: RoleEnum.MASTER,
            name: 'Portfolio',
            icon: 'ri:grid-line',
            path: '/master-office/portfolio',
            child_pages: [],
            show_in_mobile: false,
            show_in_desktop: true,
        }
        ])
}