import { defineStore } from 'pinia';
import type {SpecificationType} from "~/types/Specification";
import type {CityType, CountryType, RegionType} from "~/types/Geo";
import type {LanguageType, PhoneType, ReviewTagType, RoleType} from "~/types/Dictionary";
import type {PageCardType} from "~/types/Page";
import {fetchData} from "~/utils/api";
import {endpoints} from "~/constants/endpoints";
import {RoleEnum} from "~/types/User";

interface GlobalState {
    server_time: string | null
    specifications: SpecificationType[]
    locations: {
        cities: CityType[],
        regions: RegionType[],
        countries: CountryType[],
    }
    review_tags: ReviewTagType[]
    languages: LanguageType[]
    roles: RoleType[]
    phone_codes: PhoneType[]
    rule_pages: {
        privacy_page: PageCardType | null,
        rules_page: PageCardType | null,
    },
    educations: {
        documentId: string,
        name: string,
    }[],
    employments: {
        documentId: string,
        name: string,
    }[],
    loading: boolean
}

export const useGlobalStore = defineStore('globalStore', {
    state: (): GlobalState => ({
        server_time: null,
        specifications: [],
        locations: {
            cities: [],
            regions: [],
            countries: [],
        },
        review_tags: [],
        languages: [],
        phone_codes: [],
        roles: [],
        rule_pages: {
            privacy_page: null,
            rules_page: null,
        },
        educations: [],
        employments: [],
        loading: false
    }),
    getters: {
        parentSpecifications: (state) => state.specifications.filter(item => !item.parent_id),
        childSpecifications: (state) => (parent_id: string | null = null): SpecificationType[] => {
            if(!parent_id) return []
            return state.specifications.filter(item => item.parent_id === parent_id);
        },
        locationsList: (state) => {
            return state.locations.countries.map(country => {
                const countryWithRegions: any = {...country}
                countryWithRegions.regions = state.locations.regions.filter(region => region.country_id === country.documentId)
                return countryWithRegions
            })
        },
        roleMasterId: (state) => state.roles.find(item => item.name === RoleEnum.MASTER)?.documentId,
        roleCustomerId: (state) => state.roles.find(item => item.name === RoleEnum.CUSTOMER)?.documentId,
    },
    actions: {
        async fetchSettings() {
            this.loading = true;
            return await fetchData(endpoints.settings.get, {
                method: "GET",
            }).then(({data, meta}) => {
                this.setSettings(data)
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },
        setSettings(payload: any){
            if(payload?.specifications) this.specifications = payload.specifications;
            if(payload?.locations && payload.locations?.cities) this.locations.cities = payload.locations.cities;
            if(payload?.locations && payload.locations?.regions) this.locations.regions = payload.locations.regions;
            if(payload?.locations && payload.locations?.countries) this.locations.countries = payload.locations.countries;
            if(payload?.review_tags) this.review_tags = payload.review_tags;
            if(payload?.languages) this.languages = payload.languages;
            if(payload?.roles) this.roles = payload.roles;
            if(payload?.phone_codes) this.phone_codes = payload.phone_codes;
            if(payload?.rule_pages) this.rule_pages = payload.rule_pages;
            if(payload?.employment) this.employments = payload.employment.map(item => {
                item.name = item.text
                return item
            });
            if(payload?.education) this.educations = payload.education;
        },
        setServerTime(datetime: string){
            this.server_time = datetime;
        }
    },
})