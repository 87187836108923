<script setup lang="ts">
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import type {HTMLAttributes} from 'vue'
import {cn} from '@/lib/utils'

const userStore = useUserStore()
const {t} = useI18n()
const props = defineProps({
    showVerifyLabel: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    input_class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    required: {
        type: Boolean,
        default: true
    }
})

const passwordVisible = ref(false)

const usedPlaceholder = computed(() => {
    return props.placeholder || t('Password')
})

const validateSchema = ref()
validateSchema.value =
    z.string()
        .min(8, {message: "Password must be at least 8 characters long"})
        .max(40, {message: "Password must be no longer than 40 characters"})

if(!props.required){
    validateSchema.value = validateSchema.value.optional()
}

const {value: passwordField, errorMessage, setValue} = useField('password', toTypedSchema(validateSchema.value));

const handleInput = (event: Event) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
    setValue(inputElement.value, true)
};
</script>

<template>
    <UiFormField v-slot="{ componentField }" name="password">
        <UiFormItem class="relative">
            <UiFormLabel>{{ $t("Password") }}</UiFormLabel>
            <UiFormControl>
                <UiInput class="ys-form-input ys-password pl-9 pr-9"
                         :type="passwordVisible ? 'text' : 'password'"
                         :placeholder="usedPlaceholder"
                         @input="handleInput"
                         icon="ri:lock-2-line"
                         v-bind="componentField"/>

                <span @click="passwordVisible = !passwordVisible"
                      class="absolute flex items-center justify-center top-[27px] px-2 text-gray-500 rtl:end-0 ltr:start-0">
<!--                      <Icon size="19" class="ml-1 cursor-pointer" name="ri:eye-line"/>-->
                </span>
            </UiFormControl>
            <UiFormMessage />
        </UiFormItem>
    </UiFormField>
</template>