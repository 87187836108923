<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { PinInputInput, type PinInputInputProps, useForwardProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<PinInputInputProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <PinInputInput v-bind="forwardedProps"
                 class="ys-otp-input border"
                 :class="cn('relative text-center focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:relative focus:z-10 flex items-center justify-center border-y border-r border-neutral-200 text-sm transition-all', props.class)" />
</template>
