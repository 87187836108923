<script setup lang="ts">
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import type {HTMLAttributes} from 'vue'

const emit = defineEmits<{
    complete: [pin: string]
}>()

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    }
})

const {t} = useI18n()

const validateSchema = z.array(z.string())
    .length(4, t("Invalid input"))
    .refine((val) => val.every(v => /^\d$/.test(v)), t("Invalid input"))

const {value: pin, errorMessage, setValue} = useField('pin', toTypedSchema(validateSchema));

const handleComplete = (value: string[]) => {
    if (value.length === 4) {
        emit('complete', value.join(''))
    }
}

const handleUpdate = (value: string[]) => {
    setValue(value.filter(Boolean))
    handleComplete(value)
}
</script>

<template>
    <UiFormField v-slot="{ componentField }" name="pin">
        <UiFormItem class="text-center">
            <UiFormControl>
                <UiPinInput
                        id="pin-input"
                        v-model="pin"
                        placeholder="○"
                        :class="props.class"
                        otp
                        type="number"
                        :disabled="disabled"
                        :name="componentField.name"
                        @update:model-value="handleUpdate"
                >
                    <UiPinInputGroup class="text-4xl mx-auto">
                        <UiPinInputInput
                                v-for="index in 4"
                                :key="index"
                                :index="index - 1"
                                class="text-2xl"
                        />
                    </UiPinInputGroup>
                </UiPinInput>
            </UiFormControl>
            <UiFormMessage/>
        </UiFormItem>
    </UiFormField>
</template>