<script setup lang="ts">
import {useUserStore} from "~/store/user.store";
import {Toaster} from "~/components/ui/toast";

const { locale } = useI18n()

const dir = computed(() => locale.value === 'ar' ? 'rtl' : 'ltr')
const lang = computed(() => locale.value === 'ar' ? 'ar' : 'en')

useHead({
    htmlAttrs: {
        dir: dir,
        lang: lang,
    },
    link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'mask-icon', color: "#000000" },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    ],
    meta: [
        {
            name: 'theme-color',
            content: '#0C3B2E'
        },
    ]
})

const globalStore = useGlobalStore()
const userStore = useUserStore()
globalStore.fetchSettings()
//userStore.restoreState()

if(process.client){
    setInterval(() => {
        //userStore.fetchMessageCount()
    }, 60 * 1000)
}

const isProduction = process.env.NODE_ENV === 'production'

</script>
<template>
    <Histats v-if="isProduction" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <Toaster />
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>