import { io, Socket } from 'socket.io-client'

export default defineNuxtPlugin(() => {
    const socket: Socket = io('wss://api2.yourservice.ae', {
        autoConnect: false,
        transports: ['websocket']
    })

    return {
        provide: {
            io: socket
        }
    }
})