<script setup lang="ts">
import FooterLogo from '~/assets/img/ys_footer_logo.svg'; // Импорт логотипа футера

import {useWindowSize} from "@vueuse/core";
import {RoleEnum} from "~/types/User";

// Получение состояний из Pinia stores
 // Хранилище для авторизации
const userStore = useUserStore(); // Хранилище профиля пользователя
const Loader = useIsLoadingStore()
const globalStore = useGlobalStore()
const {width, height} = useWindowSize()
const config = useRuntimeConfig()
const route = useRoute()

const isHidden = computed(() => {
    return width.value && width.value < 1024 && ['master_office_chats', 'customer_office_chats'].includes(route.name)
})
</script>

<template>
  <footer v-if="!isHidden" class="ys-footer pt-8 font-medium mt-10 pb-20 md:pb-8">
    <div class="container">
      <!-- Логотип футера -->
      <div class="mb-8">
        <NuxtLink to="/" class="cursor-pointer block w-fit mx-auto md:mx-0">
          <FooterLogo class="ys-logo"/>
        </NuxtLink>
      </div>
      <!-- Основная навигация в футере -->
      <div class="flex flex-wrap md:flex-nowrap justify-between items-start pb-7 border-b border-gray-200">
        <nav class="flex w-full md:w-auto text-center md:text-left mb-4 md:mb-0 flex-col md:flex-row xl:space-x-36 sm:space-x-8">
          <ul class="font-medium lg:columns-3 gap-20">
            <li class="mb-2">
                <!-- Элемент меню, если пользователь не авторизован -->
                <LayoutsAuthMainElement :icon="false" class="font-medium" v-if="!userStore.isAuthenticated"/>

                <!-- Кнопка выхода из аккаунта, если пользователь авторизован -->
                <UiButton :disabled="Loader.isLoading" class="ys-button" v-if="userStore.isAuthenticated" @click="userStore.logout">
                  <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
                  <span v-else>{{ $t("Logout") }}</span>
                </UiButton>
            </li>
            <li class="mb-2">
                <template v-if="userStore.isAuthenticated">
                    <!-- Ссылка для создания заказа, доступная пользователю с ролью 'CUSTOMER' -->
                    <nuxt-link 
                        v-if="userStore.user.user_role === RoleEnum.CUSTOMER"
                        :to="{ name: 'order_create' }"
                        class="hover:text-gray-400">
                        <p>{{ $t("Create task") }}</p>
                    </nuxt-link>

                    <!-- Ссылка для поиска задач, доступная пользователю с ролью 'MASTER' -->
                    <nuxt-link 
                        v-if="userStore.isAuthenticated && userStore.user.user_role === RoleEnum.MASTER"
                        :to="{ name: 'master-office-task-search' }"
                        class="hover:text-gray-400">
                        <p>{{ $t("Tasks search") }}</p>
                    </nuxt-link>
                </template>
                <template v-else>
                    <!-- Элемент меню, если пользователь не авторизован -->
                    <LayoutsAuthMainElement
                        :icon="false" :className="'ys-button hover:text-gray-400'"
                        :title="$t('Create task')"
                    />
                </template>
            </li>
<!--            <li class="mb-2">
                <NuxtLink to="/help" class="hover:text-gray-600">{{ $t("Help & Support") }}</NuxtLink>
            </li>-->
            <li class="mb-2">
                <NuxtLink to="/aboutus" class="hover:text-gray-600">{{ $t("About us") }}</NuxtLink>
            </li>
            <li class="mb-2">
                <NuxtLink to="/contacts" class="hover:text-gray-600">{{ $t("Contacts") }}</NuxtLink>
            </li>
          </ul>
        </nav>

        <!-- Иконки приложений для мобильных устройств -->
        <div v-if="config.public.playstoreLink || config.public.appstoreLink" class="flex w-full md:w-fit justify-center gap-5">
            <a :href="config.public.playstoreLink" target="_blank" class="w-fit">
                <NuxtImg class="ys-mobile-logo" src="/home_page/googleplay.svg" />
            </a>
            <a :href="config.public.appstoreLink" target="_blank" class="w-fit">
                <NuxtImg class="ys-mobile-logo" src="/home_page/appstore.svg" />
            </a>
        </div>

      </div>

      <!-- Навигация в нижней части футера -->
      <div class="flex justify-between items-start">
        <nav class="pt-4 md:pt-7 pb-5 w-full">
          <div class="flex flex-wrap md:space-x-8">
            <!-- Кнопка выбора местоположения и другие элементы навигации -->
<!--            <CustomUiButtonLocationSelect btn_class="max-h-[36px] px-2 text-main" />-->
            <LayoutsLanguageSelector class="lg-selector-for-footer mx-auto mb-6 md:mb-0 md:ml-0"/>

            <!-- Ссылки на политику конфиденциальности и условия обслуживания -->
             <div class="flex w-full md:w-fit gap-2 md:gap-6 justify-center text-center" v-if="globalStore.rule_pages">
              <NuxtLink v-if="globalStore.rule_pages.privacy_page" class="my-auto w-full md:w-auto hover:text-gray-400 cursor-pointer" :to="`/page/${globalStore.rule_pages.privacy_page.documentId}`">
                {{ globalStore.rule_pages.privacy_page.name}}
              </NuxtLink>
              <NuxtLink v-if="globalStore.rule_pages.rules_page" class="my-auto w-full md:w-auto hover:text-gray-400 cursor-pointer" :to="`/page/${globalStore.rule_pages.rules_page.documentId}`">
                {{ globalStore.rule_pages.rules_page.name}}
              </NuxtLink>
             </div>
            
          </div>
        </nav>
      </div>
      <!-- Текст с копирайтом -->
      <div class="text-gray-400 text-center md:text-left">
        © {{ new Date().getFullYear() }} YourService
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.ys-mobile-logo {
  width: 125.62px;
  height: 35px;
  box-shadow: 0 1px 2px 0 var(--ys-button-shadow-inset) inset;
  border-radius: 6px;
}


.ys-footer-nav-button {
  @apply my-auto cursor-pointer;
  color: rgb(0, 0, 0);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
}

.ys-footer-nav-button:hover {
  background: unset;
}

</style>


