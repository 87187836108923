<script lang="ts" setup>
import { RoleEnum } from "~/types/User";
import {useWindowSize} from '@vueuse/core'
import {type TabItem, useNavTabs} from "~/composables/useNavTabs";

const navTabs = useNavTabs()
const userStore = useUserStore();
const route = useRoute()
const {width, height} = useWindowSize()

const tabList = navTabs.value.filter(item => item.role === RoleEnum.MASTER)
const isCurrentRoute = (item: TabItem) => {
    return route.path === item.path || item.child_pages.includes(route.path)
}

</script>

<template>
    <div class="min-h-screen flex flex-col">
        <!-- <LoaderLottieAnimation /> --> <!-- Анимация загрузки (Lottie) -->
        <LayoutsHeader /> <!-- Компонент шапки сайта -->

        <div class="container mx-auto px-4 flex flex-col flex-grow lg:min-h-[600px] mb-10 lg:mb-0" :class="{
            '!mb-0': width && width < 1024 && ['master-office-chats', 'customer-office-chats'].includes(route.name)
        }">
            <div class="my-8 flex justify-start flex-grow  flex-wrap md:flex-nowrap" :class="{
                '!mt-4': width && width < 1024 && ['master-office-chats', 'customer-office-chats'].includes(route.name)
            }">
                <div class="ys-profile-menu md:block hidden ys-profile-menu w-full md:w-[230px] lg:w-[272px] md:pr-4"> 
                    <div class="ys-profile-logo lg:p-3 lg:block hidden">
                        <PagesOfficeUserProfileCard :username="userStore.user?.username" :role="userStore.user?.user_role"
                            :email="userStore.user?.email" :photo="userStore.user?.avatar?.formats?.small?.url"
                            @click="navigateTo({name: userStore.user.user_role === RoleEnum.CUSTOMER ? 'customer_office' : 'master_office'});" />
                    </div>
                    <div class="ys-profile-menu-list">
                        <div v-for="item in userStore.user.user_roles">
                            <div class="ys-master-mode-box mb-5 flex items-center justify-between"
                                v-if="item === 'MASTER'">
                                {{ $t("Specialist mode") }}
                                <PagesOfficeMasterModeSwitcher />
                            </div>
                        </div>
                        <div class="list-title">{{ $t("MAIN") }}</div>
                        <NuxtLink class="ys-profile-menu-item flex items-center my-1 justify-between"
                            :class="{ current: isCurrentRoute(item) }" :to="item.path" v-for="item in tabList">
                            <span>
                                <Icon size="18" class="menu-item-icon ltr:mr-1 rtl:ml-1" :name="item.icon" />
                                {{ $t(item.name) }}
                            </span>
                            <span class="flex items-center">
                                <span class="new-messages-icon" v-if="item.name === 'Chats' && userStore.unread_messages_count">{{ userStore.unread_messages_count }}</span>
                                <Icon v-if="isCurrentRoute(item)" size="24" class="text-gray-500 rtl:rotate-180 ltr:ml-2 rtl:mr-2" name="ri:arrow-drop-right-line" />
                            </span>
                        </NuxtLink>

                        <!-- <NuxtLink v-if="userProfile.Role === 'MASTER'" to="/master-office/promotion">
                            <PagesOfficeMasterMenuPromoPlaque />
                        </NuxtLink> -->
                    </div>


                </div>
                <div class="xl:ltr:border-l xl:rtl:border-r xl:border-gray-200 grow w-full">
                    <NuxtPage />
                </div>
            </div>
        </div>

        <LayoutsFooter class="mt-auto" />
        <CustomUiBottomBar />
    </div>
</template>

<style scoped lang="scss">
.ys-profile-logo {
  height: 88px;
  gap: 10px;
}


.ys-profile-menu {
  gap: 0;

  .ys-profile-menu-list {
    // height: 222px;
    width: 100%;
    padding: 20px 20px 16px 20px;
    gap: 20px;
    
    @media(max-width:1024px){
      padding: 0;
    }

    .list-title {
      height: 24px;
      padding: 4px;
      gap: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: rgba(134, 140, 152, 1);
    }

    .ys-profile-menu-item {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(82, 88, 102, 1);

      height: 36px;
      padding: 8px 12px 8px 12px;
      gap: 8px;
      border-radius: 8px;

      &:hover {
        color: rgba(10, 13, 20, 1);
        background: rgba(246, 248, 250, 1);
      }

      &:active {
        color: rgba(10, 13, 20, 1);
        background: rgba(246, 248, 250, 1);
      }
    }

    .current {
      color: rgba(10, 13, 20, 1);
      background: rgba(246, 248, 250, 1);

      .menu-item-icon {
        color: theme('colors.primary.500');
      }
    }
  }
}

.ys-master-mode-box {
  width: 100%;
  height: 36px;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(10, 13, 20, 1);
}
</style>