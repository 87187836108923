<script lang="ts" setup>
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import type {HTMLAttributes} from 'vue';
import {cn} from '@/lib/utils';
import { parsePhoneNumberWithError, AsYouType, getCountryCallingCode } from 'libphonenumber-js';

const props = defineProps({
    showVerifyLabel: Boolean,
    disabled: Boolean,
    placeholder: String,
    class: String as PropType<HTMLAttributes['class']>,
    input_class: String as PropType<HTMLAttributes['class']>,
    required: {
        type: Boolean,
        default: true
    },
    defaultCountry: {
        type: String,
        default: 'AE'
    },
    name: {
        type: String,
        default: 'phone'
    }
});

const userStore = useUserStore();
const {t} = useI18n();

// Форматирование телефона при вводе
const asYouType = ref(new AsYouType(props.defaultCountry));
const currentCountry = ref(props.defaultCountry);
const translatedPlaceholder = computed(() => {
    if(props.placeholder) return props.placeholder;
    return t('Enter the phone number')
})

// Валидация номера
const validationSchema = computed(() => {
    const schema = z.string()
        .refine((value) => {
            try {
                const phoneNumber = parsePhoneNumberWithError(value, currentCountry.value);
                return phoneNumber.isValid();
            } catch {
                return false;
            }
        }, {
            message: t('Invalid phone number'),
        });

    return props.required ? schema : schema.optional();
});

const {value: phoneValue, errorMessage, setValue} = useField(
    props.name,
    toTypedSchema(validationSchema.value),
    {
        initialValue: ''
    }
);

const inputRef = ref<HTMLInputElement>();

watch(phoneValue, (newValue) => {
    if (newValue && inputRef.value) {
        asYouType.value = new AsYouType();
        const formattedNumber = asYouType.value.input(newValue);
        inputRef.value.value = formattedNumber;
    }
});

// Обработка ввода
const handlePhoneInput = (event: Event) => {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/^[87]/, '+7'); // Заменяем и 8, и 7 на +7
    if (!value.startsWith('+')) {
        value = '+' + value;
    }

    asYouType.value = new AsYouType();
    const formattedNumber = asYouType.value.input(value);

    if (asYouType.value.getCountry()) {
        currentCountry.value = asYouType.value.getCountry()!;
    }

    const cleanNumber = asYouType.value.getNumber()?.number || '';
    setValue(cleanNumber);

    input.value = formattedNumber;
};

// Определение иконки страны
const countryFlag = computed(() => {
    return `circle-flags:${currentCountry.value.toLowerCase()}`;
});
</script>

<template>
    <UiFormField v-slot="{ componentField, meta }" :name="name">
        <UiFormItem :class="cn('relative', props.class)">
            <UiFormLabel>
                <div class="flex justify-between items-center mb-1">
                    <p>{{ $t("Phone Number") }} <span v-if="required" class="text-primaryblue-500">*</span></p>
                    <template v-if="showVerifyLabel">
                        <p v-if="userStore.user?.phone" class="ltr:ml-4 rtl:mr-4 ys-text-verified flex">
                            {{ $t("Verified") }}
                            <Icon name="ri:check-line" size="16"/>
                        </p>
                        <p v-else class="ltr:ml-4 rtl:mr-4 ys-text-verify flex">
                            {{ $t("Verify") }}
                            <Icon name="ri:arrow-right-up-line" size="16"/>
                        </p>
                    </template>
                </div>
            </UiFormLabel>
            <UiFormControl>
                <div :class="cn('relative flex items-center border border-gray-200 rounded-xl', props.input_class)">
                    <div class="flex items-center px-3">
                        <Icon :name="countryFlag" class="size-4" />
                    </div>
                    <UiInput
                            ref="inputRef"
                            :disabled="disabled"
                            :placeholder="translatedPlaceholder"
                            :model-value="phoneValue"
                            class="flex-grow border-none rounded-xl"
                            type="tel"
                            @input="handlePhoneInput"
                    />
                </div>
                <UiFormMessage/>
            </UiFormControl>
        </UiFormItem>
    </UiFormField>
</template>

<style lang="scss" scoped>
.ys-text-verified {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  color: rgba(45, 159, 117, 1);
}

.ys-text-verify {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  color: rgba(242, 174, 64, 1);
}
</style>