<script lang="ts" setup>
import { ErrorMessage } from 'vee-validate'
import { toValue } from 'vue'
import { useFormField } from './useFormField'
import Error from "~/components/layouts/notice/Error.vue";

const { name, formMessageId } = useFormField()
</script>

<template>
  <ErrorMessage
    :id="formMessageId"
    as="p"
    :name="toValue(name)"
    v-slot="{ message }"
    class="text-sm font-medium text-red-500 top-auto left-auto"
  >
  <Error :message="message"/>
</ErrorMessage>
</template>
