<script setup lang="ts">
import {onClickOutside} from '@vueuse/core';
import {ref} from "vue";
import ExistenceByPhone from "~/components/layouts/authForms/ExistenceByPhone.vue";
import LoginByPhone from "~/components/layouts/authForms/LoginByPhone.vue";
import ResetPassByEmail from "~/components/layouts/authForms/ResetPassByEmail.vue";
import ResetPassByPhone from "~/components/layouts/authForms/ResetPassByPhone.vue";
import RegisterByEmail from "~/components/layouts/authForms/RegisterForm.vue";
import LoginForm from "~/components/layouts/authForms/LoginForm.vue";
import RegisterForm from "~/components/layouts/authForms/RegisterForm.vue";

const props = defineProps({
  icon: Boolean,
  className: String,
  title: String,
  startIcon: String
});

const isOpen = ref(false)

const flow = ref('email')
const action = ref('login')

const initialUserData = ref({
    email: null,
    phone: null,
})

const target = ref(null);
const userStore = useUserStore();
const statesStore = useStatesStore();

function clear() {
  statesStore.useClearCustomStates();
}

onClickOutside(target, clear);

watch(flow, (val, oldval) => {
    action.value = 'login'
})

watch(isOpen, (val, oldval) => {
    flow.value = 'email'
    action.value = 'login'
})

function toRegistration(props){
    if(props.initialUserData) initialUserData.value = props.initialUserData
    flow.value = props.type || 'email'
    action.value = 'register'
}
</script>

<template>
    <UiDialog class="" v-model:open="isOpen">
      <UiDialogTrigger as-child>
        <UiButton :class="className || 'ys-button hover:text-amber-50'">
          <Icon v-if="props.startIcon" size="18" class="ml-1" :name="props.startIcon"/>
          {{ props.title || $t("Join") }}
          <Icon v-if="props.icon" size="18" class="ml-1" name="ri:user-line"/>
        </UiButton>
      </UiDialogTrigger>
      <UiDialogContent class="max-w-[440px] bg-white py-4 px-8 shadow-xl !rounded-3xl border-0" v-if="!userStore.isAuthenticated">
          <UiDialogClose class="absolute top-4 ltr:right-4 rtl:left-4">
              <Icon name="ri:close-line" size="20px" class="text-gray-500 translate-all hover:text-gray-700"/>
          </UiDialogClose>

          <LoginForm
                  v-if="action === 'login'"
                  @to-registration="toRegistration"
          />

          <RegisterForm
                  v-if="action === 'register'"
                  :type="flow"
                  :initial-user-data="initialUserData"
          />
          
          <template v-if="flow === 'reset_password'">
<!--              <ExistenceByEmail
                      class="decor-unset"
                      v-if="action === 'check'"
              />-->
              
              
<!--              <ResetPassByEmail
                      v-if="action === 'reset_password'"
              />
              <ResetPassByPhone
                      class="decor-unset"
                      v-if="action === 'reset_password'"
              />-->
          </template>
      </UiDialogContent>
    </UiDialog>
</template>

<style scoped lang="scss">
.decor-unset {
  box-shadow: unset;
}

.master-catalog-button-outline {
  color: rgba(45, 159, 117, 1);
  height: 40px;
  padding: 10px;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid rgba(45, 159, 117, 1);
  background: white;
  box-shadow: 0 1px 2px 0 rgba(55, 93, 251, 0.08);

  &:hover {
    background: rgba(45, 159, 117, 1);
    color: white;
  }
}

.ys-header-nav-button {
  @apply my-auto cursor-pointer;
  color: rgba(82, 88, 102, 1);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: 8px;

  &:hover {
    background: rgba(246, 248, 250, 1);
  }
}

</style>