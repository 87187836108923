export const useStatesStore = defineStore('States', () => {
    const EmailOrPhoneForm = ref(false);
    const FailedAttempts = ref(false);
    const UserForgotPassword = ref(false);
    const UserCreateAccount = ref(false);


    const IsErrorNotice = ref(false);
    const IsWarningNotice = ref(false);
    const IsSuccessNotice = ref(false);

    const IsUserExist = ref(0);
    const OTPIsVerify = ref(true);

    const UserEmail = ref('');
    const UserPhone = ref('');
    const RequestID = ref('');

    async function useClearCustomStates() {
        EmailOrPhoneForm.value = false

        FailedAttempts.value = false
        UserForgotPassword.value = false
        UserCreateAccount.value = false

        IsErrorNotice.value = false
        IsWarningNotice.value = false
        IsSuccessNotice.value = false

        IsUserExist.value = 0
        OTPIsVerify.value = true

        UserEmail.value = ''
        UserPhone.value = ''
        RequestID.value = ''
    }

    return {
        EmailOrPhoneForm,

        FailedAttempts,
        UserForgotPassword,
        UserCreateAccount,

        IsErrorNotice,
        IsWarningNotice,
        IsSuccessNotice,

        IsUserExist,
        OTPIsVerify,

        UserEmail,
        UserPhone,
        RequestID,

        useClearCustomStates,
    }
})