<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'

const props = defineProps<{
  defaultValue?: string | number
  min?: number
  max?: number
  icon?: string
  modelValue?: string | number
  class?: HTMLAttributes['class']
  maxlength?: string | number
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

function checkValueLimit(e) {
  if (props.min) {
    if (modelValue.value < props.min) {
      modelValue.value = props.min;
    }
  }
  if (props.max) {
    if (modelValue.value > props.max) {
      modelValue.value = props.max;
    }
  }
}

</script>

<template>
  <div class="relative !mt-0 w-full">
    <input
      v-bind="$attrs"
      v-model="modelValue"
      @input="checkValueLimit"
      :maxlength="maxlength"
      :class="cn('flex h-10 w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50', maxlength ? 'pr-16' : '', props.class)">
        <span v-if="icon" class="absolute flex items-center justify-center px-2 top-[50%] translate-y-[-50%]">
          <Icon size="19" class="ml-1 text-gray-500" :name="icon" />
        </span>  
        <span v-if="maxlength" class="absolute text-gray-400 right-3 top-[50%] translate-y-[-50%] z-10 font-medium text-[11px]">
            {{ props.modelValue?.length ? props.modelValue.length : 0 }} / {{ props.maxlength }}
        </span>
    </div>
</template>
