import { defineStore } from 'pinia';
import type {UserType} from "~/types/User";
import type {CookieRef} from "#app";
import {fetchData} from "~/utils/api";
import {endpoints} from "~/constants/endpoints";
import type {
    CheckCredentials, ForgotPasswordCredentials,
    LoginCredentials, OtpCredentials,
    PartRegisterCredentials,
    RegisterCredentials
} from "~/types/UserCredentials";
import {RoleEnum} from "~/types/User";
import type {PhoneType} from "~/types/Dictionary";
import type {CityType, RegionType} from "~/types/Geo";
import type {FileType, StrapiFilePostData, StrapiFileType} from "~/types/Files";
import type {PortfolioType} from "~/types/Portfolio";

interface UserStore {
    user: UserType | null
    access_token: CookieRef<string | null>
    isAuthenticated: boolean
    guest_role: RoleEnum
    phone_code: PhoneType | null
    region: RegionType | null
    unread_messages_count: number
    loading: boolean
    geoLocation: {
        lat: number
        lng: number
    } | null
    geoLocationBounds: {
        minLat: number
        maxLat: number
        minLng: number
        maxLng: number
    } | null
}

interface FileCredentials {
    files: FileType[]
    field: string
    refId: string | number
    ref: string
}

export const useUserStore = defineStore('userStore', {
    state: (): UserStore => ({
        user: null,
        access_token: useCookie('access_token', {
            maxAge: 7 * 24 * 60 * 60,
            path: '/',
            secure: true,
            sameSite: 'none',
        }),
        isAuthenticated: false,
        guest_role: RoleEnum.CUSTOMER,
        phone_code: null,
        region: null,
        unread_messages_count: 0,
        loading: false,
        geoLocation: null,
        geoLocationBounds: null,
    }),
    getters: {
        specifications: (state) => {
            if(!state.user?.portfolios) return []
            return state.user?.portfolios.flatMap(portfolio => portfolio.specifications.map(spec => spec.documentId))
        },
        currentRole: (state) => {
            return state.user ? state.user.user_role : state.guest_role
        },
        currentPhoneCode: (state) => {
            if(state.phone_code) return state.phone_code
            const globalStore = useGlobalStore()
            const dubai_phone_code = globalStore.phone_codes.find(item => item.code === 'AE')
            return dubai_phone_code || globalStore.phone_codes[0]
        },
        currentCity: (state) => {
            if(state.city) return state.city
            const globalStore = useGlobalStore()
            const dubai_city = globalStore.locations.cities.find(item => item.name === 'Dubai')
            return dubai_city || globalStore.locations.cities[0]
        },
        isPartialUserData: (state) => {
            return !state.user?.user_role
        }
    },
    actions: {
        async check(credentials: CheckCredentials) {
            this.loading = true;
            return await fetchData(endpoints.auth.check, {
                method: "GET",
                params: credentials
            }).then(({data, meta}) => {
                return data;
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async login(credentials: LoginCredentials) {
            this.loading = true;
            return await fetchData<any>(endpoints.auth.login, {
                method: "POST",
                body: credentials
            }).then(async ({data, meta}) => {
                this.setToken(data?.token)
                if(data?.user?.user_role || data?.user?.user_roles?.length){
                    this.isAuthenticated = true
                    await this.setUser(data?.user)
                } else {
                    await nextTick()
                    await this.fetchUser()
                }
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async register(credentials: RegisterCredentials) {
            this.loading = true;
            return await fetchData<any>(endpoints.auth.register, {
                method: "POST",
                body: credentials
            }).then(({data, meta}) => {
                this.setToken(data?.token)
                if(data?.user){
                    this.isAuthenticated = true
                    this.setUser(data?.user)
                }
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async update(credentials: RegisterCredentials) {
            if(!this.user?.documentId) return
            this.loading = true;
            //TODO: Убрать когда будет понятно как прикреплять аватар
            if(credentials?.avatar){
                if(this.user.avatar){
                    await fetchAuth(endpoints.files.delete(this.user.avatar.id), {
                        method: "DELETE",
                    }).then(({data, meta}) => {
                        //this.user?.avatar = null;
                    })
                }
                await this.postFile({
                    field: 'avatar',
                    ref: 'plugin::users-permissions.user',
                    refId: this.user.id,
                    files: credentials.avatar
                })
                delete credentials.avatar
            }
            return await fetchAuth<any>(endpoints.user.updateById(this.user.documentId), {
                method: "PUT",
                body: credentials,
            }).then(({data, meta}) => {
                if(data){
                    this.setUser(data)
                }
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async updateRole(newRole: RoleEnum) {
            if(!this.user?.documentId) return

            const globalStore = useGlobalStore()
            const role = globalStore.roles.find(item => item.name === newRole)
            if(!role) return

            return await fetchAuth<any>(endpoints.user.updateById(this.user.documentId), {
                method: "PUT",
                body: {
                    user_role: [role.documentId]
                },
            }).then(({data, meta}) => {
                if(data){
                    this.setUser(data)
                }
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async partRegister(credentials: PartRegisterCredentials) {
            this.loading = true;
            return await fetchData(endpoints.auth.partRegister, {
                method: "POST",
                data: credentials
            }).then(({data, meta}) => {
                return data;
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async forgotPassword(credentials: ForgotPasswordCredentials) {
            this.loading = true;
            return await fetchData(endpoints.auth.forgotPassword, {
                method: "POST",
                data: credentials
            }).then(({data, meta}) => {
                return data;
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async logout() {
            this.loading = true;
            return await fetchAuth(endpoints.auth.logout, {
                method: "POST"
            }).finally(() => {
                this.setToken(null)
                this.user = null
                this.isAuthenticated = false
                navigateTo('/')
            });
        },

        async storePortfolio(credentials: PortfolioType) {
            const portfolioId = credentials?.documentId;
            const { images, ...restCredentials } = credentials;

            const endpoint = portfolioId
                ? endpoints.user.portfolio.update(portfolioId)
                : endpoints.user.portfolio.create;

            delete restCredentials.documentId;

            // Сначала удаляем помеченные на удаление файлы
            if (images.toDelete.length) {
                await this.deleteFiles(images.toDelete);
            }

            const portfolio = await fetchAuth<PortfolioType>(endpoint, {
                method: portfolioId ? 'PUT' : "POST",
                body: {
                    ...restCredentials
                }
            }).then(({data, meta}) => {
                this.user.portfolios = [data];
                return data;
            }).catch((e) => {
                return Promise.reject(e);
            });

            // Загружаем новые файлы
            if (portfolio && images.new.length) {
                const files = await this.postFile({
                    field: 'images',
                    ref: 'api::portfolio.portfolio',
                    refId: portfolio.id,
                    files: images.new
                }).then((data) => {
                    console.log(data)
                });


            }

            return portfolio;
        },

        /*async sendOtp(credentials: OtpCredentials) {
            this.loading = true;
            return await fetchData(endpoints.auth.otp.send, {
                method: "POST",
                data: credentials
            }).then(({data, meta}) => {
                return data;
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async verifyOtp(credentials: OtpCredentials) {
            this.loading = true;
            return await fetchData<any>(endpoints.auth.otp.verify, {
                method: "POST",
                data: credentials
            }).then(({data, meta}) => {
                this.access_token = data?.access_token;
                if(data?.user){
                    this.setUser(data?.user)
                }
                this.isAuthenticated = true
                return data
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        },*/

        async fetchUser() {
            if (!this.access_token) return
            this.loading = true
            try {
                const {data: user, meta} = await fetchAuth<UserType>(endpoints.user.get, {
                    method: 'GET'
                })
                if(user){
                    this.isAuthenticated = true
                    await this.setUser(user)
                }
            } catch {
                await this.logout()
            } finally {
                this.loading = false
            }
        },

        async fetchMessageCount(){
            return await fetchAuth<any>(endpoints.user.unreadMessages, {
                method: "GET"
            }).then(({data, meta}) => {
                this.unread_messages_count = data
            });
        },

        async restoreState() {
            if (this.access_token) {
                try {
                    await this.fetchUser()
                } catch {
                    await this.logout()
                }
            } else {
                this.isAuthenticated = false
            }
        },

        setRole(role: RoleEnum){
            if(!this.user) this.guest_role = role
            if(this.user?.user_roles.some(item => item === role)) this.user.user_role = role
        },

        setPhoneCode(phoneCode: PhoneType){
            this.phone_code = phoneCode
        },

        setRegion(region: RegionType){
            this.region = region
        },

        async setUser(payload: UserType) {
            if (!payload) return null;

            const updatedUser = { ...payload };

            if (payload.user_role && typeof payload.user_role === 'object') {
                updatedUser.user_role = payload.user_role.name;
            } else if(payload?.user_roles?.length){
                updatedUser.user_role = payload?.user_roles[0].name;
            }

            this.user = updatedUser;
            if (!updatedUser.username) {
                await navigateTo({ name: 'create_account' });
            }

            return updatedUser;
        },
        
        setToken(token: string | null){
            const tokenCookie = useCookie('access_token', {
                maxAge: 7 * 24 * 60 * 60,
                path: '/',
                secure: true,
                sameSite: 'none',
            });
            tokenCookie.value = token;
            this.access_token = token;
        },

        async postFile(postData: StrapiFilePostData){
            const formData = new FormData();

            // Добавляем мета-данные
            formData.append('ref', postData.ref);
            formData.append('refId', postData.refId.toString());
            formData.append('field', postData.field);
            // Добавляем файлы
            if (Array.isArray(postData.files)) {
                console.log(postData.files)
                postData.files.forEach(file => {
                    formData.append('files', file);
                });
            } else {
                formData.append('files', postData.files);
            }

            return await fetchAuth<StrapiFileType>(endpoints.files.post, {
                method: "POST",
                body: formData
            }).then((data) => {
                return data
            }).catch((e) => {
                return Promise.reject(e);
            })
        },

        async deleteFiles(fileIds: number[]){
            const deletePromises = fileIds.map(id =>
                fetchAuth(endpoints.files.delete(id), {
                    method: 'DELETE'
                }).then(({data, meta}) => data)
            );

            return Promise.all(deletePromises);
        },
    },
});
