import type {PortfolioType, ReviewType} from "~/types/Portfolio";
import type {LanguageType} from "~/types/Dictionary";
import type {SpecificationType} from "~/types/Specification";
import type {StrapiFileType} from "~/types/Files";

export interface UserCardType {
    id: number,
    documentId: string
    username: string | null
    //name: string | null
    avatar: StrapiFileType | null
    compute_rating: number | null
    compute_review_count: number | null
}

export interface UserPageType extends UserCardType {
    is_active: boolean
    email: string | null
    phone: string | null
    gender: GenderEnum | null
    date_of_birth: string | null
    biography: string | null
    user_role: RoleEnum | null
    reviews: ReviewType[]
    specifications: SpecificationType[]
    portfolios: PortfolioType[]
    languages: LanguageType[]
}

export interface UserType extends UserPageType {
    user_roles: RoleEnum[]
    responses: string[]
}

export enum GenderEnum {
    M = 'MALE',
    F = 'FEMALE'
}

export enum RoleEnum {
    MASTER = 'MASTER',
    CUSTOMER = 'CUSTOMER',
}