<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps({
    src: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: 'md'
    },
    shape: {
        type: String,
        default: 'circle'
    },
    show_online_mark: {
        type: Boolean,
        default: false
    },
    is_online: {
        type: Boolean,
        default: false
    },
    onClick: {
        type: Function,
        default: null
    }
});

const initials = computed(() => {
    if(!props.name) return
    const names = props.name.split(' ');
    const firstInitial = names[0]?.charAt(0) || '';
    const secondInitial = names[1]?.charAt(0) || '';
    return `${firstInitial}${secondInitial}`.toUpperCase();
});

// Функция для генерации цвета на основе инициалов
const generateColor = (initials) => {
    if(!initials) return;
    let hash = 0;
    for (let i = 0; i < initials.length; i++) {
        hash = initials.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Генерация цвета в более ярком диапазоне
    const hue = hash % 360; // Используем хэш для генерации оттенка
    const saturation = 50 + (hash % 50); // Насыщенность от 50 до 100
    const lightness = 20 + (hash % 30); // Яркость от 50 до 80

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Используем HSL для генерации цвета
};

// Определение цвета аватара на основе инициалов
const avatarColor = computed(() => generateColor(initials.value));

const handleClick = (event) => {
    console.log('Click')
    if (props.onClick) {
        props.onClick(event)
    }
}
</script>

<template>
    <div class="avatar_wrapper relative w-fit flex items-center rounded-full" @click="handleClick">
        <UiAvatar :style="props?.src ? null : `background-color: ${avatarColor}`" class="cstm_style_avatar__span" :class="{
            'w-[32px] h-[32px]': props.size === '2xs',
            'w-[40px] h-[40px]': props.size === 'xs',
            'w-[60px] h-[60px]': props.size === 'sm',
            'w-[70px] h-[70px]': props.size === 'md',
            'w-[120px] h-[120px]': props.size === 'xl',
            'w-[150px] h-[150px]': props.size === '2xl',
            'rounded-xl': props.shape === 'square',
        }">
            <UiAvatarImage v-if="props.src" :src="props.src" :alt="props?.name" />
            <UiAvatarFallback v-if="initials" class="font-bold text-white" :class="{
                'text-xs': props.size === 'xs' || props.size === '2xs',
                'text-xl': props.size === 'sm',
                'text-2xl': props.size === 'md',
                'text-3xl': props.size === 'xl',
                'text-4xl': props.size === '2xl',
            }">{{ initials }}</UiAvatarFallback>
            <img v-else src="/MyOffice/avatar.png">
        </UiAvatar>
        <div v-if="show_online_mark" class="online_mark absolute bottom-0 right-0 bg-accent" :class="{
                'bg-green-500': is_online
            }"></div>
    </div>
</template>



<style scoped lang="scss">
.online_mark {
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 999px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .04);
}

.cstm_style_avatar .cstm_style_avatar__span{
    @media(max-width: 768px){
        height: 70px;
        width: 70px;
    }
}

</style>