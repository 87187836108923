<script setup lang="ts">
import {type HTMLAttributes, computed} from 'vue'
import {
  SwitchRoot,
  type SwitchRootEmits,
  type SwitchRootProps,
  SwitchThumb,
  useForwardPropsEmits,
} from 'radix-vue'
import {cn} from '@/lib/utils'

const props = defineProps<SwitchRootProps & { class?: HTMLAttributes['class'] }>()

const emits = defineEmits<SwitchRootEmits>()

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <SwitchRoot
      style="box-shadow: 0 4px 4px 0 rgba(15, 15, 16, 0.12) inset; border: unset; direction: ltr!important;"
      v-bind="forwarded"
      :class="cn(
      'peer inline-flex h-4 w-7 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent ' +
       'transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 ' +
        'focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed ' +
         'disabled:opacity-50 data-[state=checked]:bg-primary-500 data-[state=unchecked]:bg-neutral-200',
      props.class,
    )"
  >
    <SwitchThumb
        style="box-shadow: 0 2px 4px 0 rgba(22, 38, 100, 0.08), 0 4px 8px 0 rgba(22, 38, 100, 0.08),0 6px 10px 0 rgba(22, 38, 100, 0.08),0 -3px 3px 0 rgba(228, 229, 231, 1) inset;"
        :class="cn('pointer-events-none block h-3 w-3 ' +
       'rounded-full bg-white shadow-lg ring-0 ' +
        'transition-transform data-[state=checked]:translate-x-3 ' +
         'data-[state=unchecked]:translate-x-0 ml-0.5')">
      <div class="mx-auto my-1 h-1 w-1 rounded-full bg-primary-500"></div>
    </SwitchThumb>
  </SwitchRoot>
</template>
