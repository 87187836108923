<script lang="ts" setup>
import { RoleEnum } from "~/types/User";
import Categories from "~/components/pages/catalog/CategoriesCarousel.vue";

const route = useRoute()
const { parentSpecifications } = useGlobalStore()

const specificationCarouselItems = computed(() => {    
    return parentSpecifications.filter(item => !item.parent_id)
})

</script>

<template>
    <div class="min-h-screen">
        <!-- <LoaderLottieAnimation /> --> <!-- Анимация загрузки (Lottie) -->
        <LayoutsHeader /> <!-- Компонент шапки сайта -->

        <div class="container md:min-h-[950px]">
            <div class="my-4 lg:my-10">
                <Categories v-if="route?.query?.mode !== 'geo'" :items="specificationCarouselItems" :role="RoleEnum.CUSTOMER" has_arrows />
            </div>
            <NuxtPage />
        </div>

        <LayoutsFooter />
        <CustomUiBottomBar />
    </div>
</template>