export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore(); // Получение состояния авторизации пользователя

    // Если пользователь авторизован
    if (userStore.access_token && !userStore.user) {
        try {
            // Вызов API для получения профиля пользователя
            await  userStore.restoreState()
        } catch (error) {
            console.error('Error user profile:', error); // Логирование ошибки при получении профиля пользователя
        }
    }
    const isLoggedIn = userStore.isAuthenticated; // Проверка, авторизован ли пользователь

    // Если пользователь авторизован, но роль не установлена и текущий путь не связан с созданием аккаунта или условиями использования
    if (isLoggedIn && !userStore.user?.username && !to.path.includes('create-account') && !to.path.includes('terms-of-service')
        && !to.path.includes('privacy-policy')) {
        return navigateTo('/create-account'); // Перенаправление на страницу создания аккаунта
    }

    // Логика перенаправления в зависимости от авторизации пользователя и роли
    if (!isLoggedIn && to.path.includes('create-account') || // Если пользователь не авторизован и пытается перейти на страницу создания аккаунта
        isLoggedIn && userStore.user?.username && to.path.includes('create-account') || // Если пользователь авторизован и пытается снова создать аккаунт
        !isLoggedIn && to.path.includes('office') || // Если неавторизованный пользователь пытается перейти в офис
        isLoggedIn && to.path.includes('office') && !to.path.includes(userStore.user?.user_role?.toLowerCase()) || // Если пользователь авторизован, но пытается перейти в офис не своей роли
        !isLoggedIn && to.path.includes('order-create') || // Если неавторизованный пользователь пытается создать заказ
        isLoggedIn && userStore.user?.user_role === 'MASTER' && to.path.includes('order-create')) { // Если мастер пытается создать заказ
        return navigateTo('/'); // Перенаправление на главную страницу
    }

    // Удаление завершающего слэша из URL (если он есть) и перенаправление на исправленный путь
    /*const path = to.fullPath;
    if (path !== '/' && path.endsWith('/')) {
        return navigateTo(path.slice(0, -1), {replace: true});
    }*/
});